@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Poppins', sans-serif;
  }
}



:root {
    --primary-color: #007bff;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6); /* Adjust the opacity as needed */
  z-index: 999; /* Ensure the overlay appears on top of other elements */
}

.lp {
  z-index: 1000; /* Ensure the login popup appears on top of the overlay */
}

